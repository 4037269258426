<!--
 * @Author: baymax-arch 1625750783@qq.com
 * @Date: 2022-05-04 21:08:31
 * @LastEditors: baymax-arch 1625750783@qq.com
 * @LastEditTime: 2022-05-07 19:05:55
 * @FilePath: \MyBoke\src\pages\404\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<template>
  <div class="nofound">
    <div>
      <h2>页面不存在哦</h2>
    </div>
    <div class="toolMain">
      <div class="backPrev" @click="back" v-if="!!$route.redirectedFrom">
        返回上一页
      </div>
      <div class="backHome" @click="home">回首页</div>
    </div>
  </div>
</template>

<script setup>
import useLoading from "@/hooks/useLoading.js";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { onMounted, computed } from "vue-demi";
import { useHead } from "@vueuse/head";
let router = useRouter();
let route = useRoute();
console.log(route);
useLoading();
onMounted(() => {
  useStore().commit("headInfo/UP_ACTIVE_LINK", "");
});
let back = () => {
  router.go(-2);
};
useHead({
  // Can be static or computed
  title: "页面不存在",
});
let home = () => {
  router.push({
    path: "/",
  });
};
</script>

<style scoped>
.toolMain {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  height: 60px;
  width: 400px;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
}
.nofound {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--white_divFc);
  background-color: var(--white_divBgc);
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .toolMain {
    cursor: none;
  }
}
</style>